<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <b-card class="mb-0">
        <b-link class="brand-logo">
          <b-img
              :src="require('@/assets/images/logo/logo.png')"
              alt="logo"
          />

          <h2 class="brand-text text-primary ml-1">
            ERP
            <small>
              <i>by HMT</i>
            </small>
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          {{ $t('ForgotPassword?') }} 🔒
        </b-card-title>
        <b-card-text class="mb-2">
          {{ $t('Enter your email and we\'ll send you instructions to reset your password') }}
        </b-card-text>

        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationForm"
          >
            <!-- email -->
            <b-form-group
                :label="$t('Email')"
                label-for="forgot-password-email"
            >
              <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="required|email"
              >
                <b-form-input
                    id="forgot-password-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="forgot-password-email"
                    placeholder="john@example.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
                variant="primary"
                block
                type="submit"
            >
              {{ $t('SendResetLink') }}
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <b-link :to="{name:'auth-login'}">
            <icon icon="chevron-left"/>
            {{ $t('BackToLogin') }}
          </b-link>
        </b-card-text>

      </b-card>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@/utils/validations/validations'
import ToastificationContent from '@/components/toastification/ToastificationContent.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data () {
    return {
      userEmail: '',
      // validation
      required,
      email,
    }
  },
  methods: {
    validationForm () {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {

          let datas = new FormData()
          datas.set('email', this.userEmail)

          this.$store.dispatch('auth/sendResetPasswordLink', datas)
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: this.$t('Success'),
                    icon: 'check',
                    text: this.$t('MessageSent'),
                    variant: 'success'
                  },
                })
                // this.$router.push({ name: 'auth-reset-password' })
              })
              .catch(error => {
                console.log(error)
              })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "~@/assets/scss/base/pages/auth.scss";
</style>
